function load(component) {
  return () => import(`@/components/comum/${component}`)
}

function loadC(component) {
  return () => import(`@/components/${component}`)
}

export default [
  { path: '/relesccontabil', name: 'Relatório Contábil',
    meta: {
      breadcrumb: [{
        text: 'Relatório Contábil'
      }]
    },
    component: loadC('modulos/contabil/relatorios/RelEscContabil') 
  },

  { path:'/escplncontas', name: 'Plano de Contas', redirect: '/escplncontas/lista', component: { render (c) { return c('router-view') } },
    children: [
      { path:'lista', name: 'Listagem de Plano de Contas', component: load('MctDataViewer'), 
        meta: { breadcrumb: [
          { text: 'Listagem de Plano de Contas'} 
        ]},
        props: {source:"escplncontas/lista"}},
      { path: 'novo', name: 'Novo Plano de Contas', component: loadC('modulos/contabil/EscPlnContas'), 
        meta: { breadcrumb: [
          { text: 'Listagem de Plano de Contas', to: '/escplncontas' }, 
          { text: 'Novo Plano de Contas' }
        ]},      
        props: {source:"escplncontas/novo",modo:'novo'} },
      { path: ':codigo/editar', name: 'Editar Plano de Contas', component: loadC('modulos/contabil/EscPlnContas'), 
        meta: { breadcrumb: [
          { text: 'Listagem de Plano de Contas', to: '/escplncontas' }, 
          { text: 'Editar Plano de Contas' }
        ]},
        props: {source:"escplncontas/editar",modo:'editar'} },
      { path: ':codigo/deletar', name: 'Deletar Plano de Contas', component: loadC('modulos/contabil/EscPlnContas'), 
        meta: { breadcrumb: [
          { text: 'Listagem de Plano de Contas', to: '/escplncontas' }, 
          { text: 'Deletar Plano de Contas' }
        ]},      
        props: {source:"escplncontas/deletar",modo:'deletar'} },
      { path: ':codigo/visualizar', name: 'Visualizar Plano de Contas', component: loadC('modulos/contabil/EscPlnContas'), 
        meta: { breadcrumb: [
          { text: 'Listagem de Plano de Contas', to: '/escplncontas' }, 
          { text: 'Visualizar Plano de Contas' }
        ]},
        props: {source:"escplncontas/editar",modo:'visualizar'} },
    ]
  },

];
