function load(component) {
    return () => import(`@/components/comum/${component}`)
  }
  
  function loadC(component) {
    return () => import(`@/components/${component}`)
  }
  
    export default [
      /* CRM */
  
      //{ path: '/dashcrm', name: 'DashFin', component: loadC('dashboards/EscModFinanceiro')},
      { path: '/dashcrm', name: 'DashCRM', component: loadC('dashboards/EscModCRM')},
      { path: '/crm/funil', name: 'Funil', component: loadC('modulos/crm/CrmFunil')},
      { path: '/crm/funil/:codigo', name: 'FunilKaban', component: loadC('modulos/crm/CrmFunilKaban')},

      { path:'/crmtags', name: 'Tags', redirect: '/crmtags/lista', component: { render (c) { return c('router-view') } },
      children: [
        { path: 'lista', name: 'Listagem de Tags', component: load('MctDataViewer'), props: { source: "crmtags/lista", titulo: "Tags" } },
        { path: 'novo', name: 'Nova Tags', component: load('MctFormViewer'), props: { source: "crmtags/novo", titulo: "Tags", modo: 'novo' } },
        { path: ':codigo/editar', name: 'Editar Tags', component: load('MctFormViewer'), props: { source: "crmtags/editar", titulo: "Tags", modo: 'editar' } },
        { path: ':codigo/deletar', name: 'Deletar Tags', component: load('MctFormViewer'), props: { source: "crmtags/deletar", titulo: "Tags", modo: 'deletar' } },
        { path: ':codigo/visualizar', name: 'Visualizar Tags', component: load('MctFormViewer'), props: { source: "crmtags/editar", titulo: "Tags", modo: 'visualizar' } },
      ]
    },

  ];
  