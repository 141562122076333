const NotFound = () => import('@/components/NotFound')
const MctLogar = () => import('@/components/seguranca/MctLogar')
const MctOpenFile = () => import('@/components/comum/MctOpenFile')

import Comum from './comum'
import Logradouro from './logradouro'
import SigEs from './siges'
import Financeiro from './financeiro'
import Contabil from './contabil'
import CRM from './crm'
import Portal from './portal'

const routes = [
  { path:'/logar', name: 'Logar', component: MctLogar},
  { path: '/not-found', component: NotFound },
  { path: '*', component: NotFound },
  { path: '/openfile', component: MctOpenFile },
  
  { path: '/',  
    name: 'Início',      
  },
  ...Comum,
  ...Logradouro, 
  ...SigEs, 
  ...Financeiro, 
  ...Contabil, 
  ...CRM
    
].concat(Portal);

export default routes