import Vue from 'vue';
import * as MutationTypes from './MutationTypes';
/*import Cookies from "js-cookie";*/
import axios from "@/http";
import axiosip from "axios";

const state = {

    infoLogado: [],
    token: localStorage.getItem('mct_user_token'),//Cookies.get('mct_user_token'),
    usuLogado: false,
    infoModulo: [],
    Entidade: 0,
    Escola: 0,
    EntidadeOrc: '',
    Exercicio: 0,
    //Competencia: '01',
    infoSubModulo: [],
    IP: '',
    baseURL: 'http://sigesp.mct/sigesp-back/',
    infoMenu: [],
    infoMenuSideBar: [],
    infoEntidade: {},
    infoEscola: {},
    layout: 'div'    
};

const mutations = {
    [MutationTypes.USU_LOGADO](state, {user}) {
        state.infoLogado = user.user
        //state.infoModulo = 0
        state.Entidade = Number(user.entidade.ent_codigo)
        state.Escola = Number(user.escola.esc_codigo)
        state.Exercicio = Number(user.exercicio)
        
        state.infoEntidade = user.entidade
        state.infoEscola = user.escola     

        //Cookies.set('mct_user_token', response.token);
        localStorage.setItem('mct_user_token', user.token)
        localStorage.setItem('mct_entidade', state.Entidade)
        localStorage.setItem('mct_escola', state.Escola)
        localStorage.setItem('mct_exercicio', state.Exercicio)
        axios.defaults.params = {'mct_entidade': state.Entidade,'mct_ano_letivo': state.Exercicio,'mct_escola': state.Escola,'mct_ip': state.IP}
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('mct_user_token')
    },
    [MutationTypes.USU_LOGOUT](state) {
        state.token = '';
        state.infoLogado = [];
        state.infoModulo = [];
        state.Entidade = 0;
        state.Escola = 0;
        state.EntidadeOrc = '';
        state.Exercicio = 0;
        state.infoEntidade = {}
        state.infoEscola = {}     

        //state.Competencia = '';
        state.infoSubModulo = [];
        //Cookies.remove('mct_user_token');
        axios.defaults.params = '';
        //console.log('deslogado');
    },
    [MutationTypes.USU_LOGAR_SUCCESS](state, response) {
        state.usuLogado = response
    },
    [MutationTypes.USU_MODULO](state, response) {
        state.infoModulo = response
        state.infoSubModulo = []
    },
    [MutationTypes.USU_SUBMODULO](state, response) {
        //state.infoModulo = response.modulo
        state.infoSubModulo = response
    },
    [MutationTypes.MENU](state, response) {
        state.infoMenu = response
    },
    [MutationTypes.MENU_SIDEBAR](state, nav) {
        state.infoMenuSideBar = nav
    },
    [MutationTypes.ENTIDADE](state, response) {
        state.infoEntidade = response
    },
    [MutationTypes.USU_EXERCICIO](state, response) {
        state.Exercicio = response
        axios.defaults.params = {'mct_entidade': state.Entidade,'mct_ano_letivo': state.Exercicio,'mct_escola': state.Escola,'mct_ip': state.IP}
    },
    [MutationTypes.USU_ULTEXERCICIO](state, response) {
        /*state.Exercicio = response[0].exe_codigo
        state.EntidadeOrc = response[0].ug_orcamento;
        state.Competencia = response[0].comp_codigo
        localStorage.setItem('mct_exercicio', state.Exercicio)
        axios.defaults.params = {'mct_entidade': state.Entidade,'mct_exercicio': state.Exercicio,'mct_competencia': state.Competencia}*/

        //state.Exercicio = 0
        //state.Escola = 1
        //state.Entidade = state.infoLogado.ent_codigo;
        axios.defaults.params = {'mct_entidade': state.Entidade,'mct_ano_letivo': state.Exercicio,'mct_escola': state.Escola,'mct_ip': state.IP}

    },
    [MutationTypes.USU_IP](state, response) {
        state.IP = response
        axios.defaults.params = {'mct_entidade': state.Entidade,'mct_ano_letivo': state.Exercicio,'mct_escola': state.Escola,'mct_ip': state.IP}
    },
    [MutationTypes.LAYOUT](state, response) {
      state.layout = response
  },
};

const actions = {
    [MutationTypes.USU_BUSCA]({commit}) {
        axios.get('/user')
            .then((response) => {
                if (response.status === 200){
                    commit(MutationTypes.USU_LOGAR_SUCCESS, true);
                }else {
                    commit(MutationTypes.USU_LOGOUT)
                }
            })
            .catch(function(response,error) {
                if (response.response.status === 401){
                    commit(MutationTypes.USU_LOGOUT)
                }
              })
            ;
    },
    async [MutationTypes.USU_LOGADO]({commit,dispatch}, response) {
        commit(MutationTypes.USU_LOGADO, response);

        await axios.get(`/modulos/lista`)
        .then( res => {
          let modulo = res.data.data;
          if (modulo.length === 1){
            modulo = modulo[0];
            const { submodulo } = modulo;
            if (submodulo.length == 0 && modulo.mod_codigo != 0 && modulo.mod_codigo != null && modulo.mod_codigo != 'undefined')
            {
              dispatch('USU_MODULO', modulo);
              dispatch('MENU');
              const LayoutDefault = Vue.component('LayoutDefault', require('@/components/layouts/LayoutDefault.vue').default);
              dispatch("LAYOUT",LayoutDefault);
              dispatch('PAGINAINICIAL',response);
              //response.router.push({path: '/'})
            }else
            if (submodulo.length > 0 && modulo.mod_codigo != 0 && modulo.mod_codigo != null && modulo.mod_codigo != 'undefined')
            {
              dispatch('USU_MODULO', modulo);
              if (submodulo.length === 1){
                dispatch('USU_SUBMODULO', submodulo);
                dispatch('MENU');
                const LayoutDefault = Vue.component('LayoutDefault', require('@/components/layouts/LayoutDefault.vue').default);
                dispatch("LAYOUT",LayoutDefault);
                dispatch('PAGINAINICIAL',response);
                //response.router.push({path: '/'})
              } else {
                response.router.push({path: '/submodulos'})
              }
            }
          }
        })
        .catch(res => {
            console.log(res.message)
        })
    },
    [MutationTypes.USU_LOGAR_SUCCESS]({commit}, response) {
        commit(MutationTypes.USU_LOGAR_SUCCESS, response);
    },
    [MutationTypes.USU_LOGOUT]({commit}) {
        commit(MutationTypes.USU_LOGOUT);
    },
    [MutationTypes.USU_MODULO]({commit,dispatch}, response) {
      commit(MutationTypes.USU_MODULO, response);
    },
    [MutationTypes.USU_SUBMODULO]({commit,dispatch}, response) {
        commit(MutationTypes.USU_SUBMODULO, response);
    },
    [MutationTypes.USU_EXERCICIO]({commit}, response) {
        commit(MutationTypes.USU_EXERCICIO, response);
    },
    [MutationTypes.MENU]({commit}) {
        let submodulo = state.infoSubModulo.smod_codigo !== undefined ? state.infoSubModulo.smod_codigo : 0;

        //MENU
        axios.get(`menu/${state.Entidade}/${state.infoModulo.mod_codigo}/${submodulo}/${state.infoLogado.per_codigo}`)
        .then(function(response) {
            commit(MutationTypes.MENU,response.data);
        })
        .catch(function(response) {
            console.log(response)
        })

        //SIDE BAR
        axios.get(`menu/0/${state.infoLogado.ent_codigo}/${state.infoModulo.mod_codigo}/0/${state.infoLogado.per_codigo}`)
        .then(response => {
          const {perfis} = state.infoLogado;
          const dashs = perfis.map(ele => {
            if (ele.per_tipo === 1){
              return  {
                name: 'Administrativo',
                url: '/dashadmin',
                icon: 'fa-tachometer',
              };
            } else if (ele.per_tipo === 2){
              return {
                name: 'Financeiro',
                url: '/dashfinanceiro',
                icon: 'fa-tachometer',
              };
            } else if (ele.per_tipo === 3){
              return {
                name: 'Vendas / CRM',
                url: '/dashcrm',
                icon: 'fa-tachometer',
              };
            } else if (ele.per_tipo === 4){
              return {
                name: 'Pedagógica',
                url: '/dashsecretaria',
                icon: 'fa-tachometer',
              };
            }          
          }).filter(ele => ele !== undefined);

          const nav = [];
          nav.push({
              name: 'Dashboards',
              url: '/dashboards',
              icon: 'fa-tachometer',
              title: false,
              children : dashs
            });

          nav.push(...response.data);
          /*nav.push(
            {
              name: 'Alterar Senha',
              url: '/alterarsenha',
              icon: 'fa-unlock',
              class: 'mt-auto',
              variant: 'success'
            },
            {
              name: 'Configurações',
              url: '/config',
              icon: 'fa-cogs',
              variant: 'danger'
            }
          );*/
          commit(MutationTypes.MENU_SIDEBAR,nav);
        })
        .catch(response => {

        })        
    },
    [MutationTypes.PAGINAINICIAL]({commit}, response) {
      /*const { perfil } = state.infoLogado;
      if (perfil.per_tipo === 1){
        response.router.push({path: '/dashadmin'})
      }else if (perfil.per_tipo === 2){
        response.router.push({path: '/dashfinanceiro'})
      }else if (perfil.per_tipo === 3){
        response.router.push({path: '/dashvendas'})
      }else if (perfil.per_tipo === 4){
        response.router.push({path: '/dashsecretaria'})
      }else{*/
        response.router.push({path: '/'})
      //}
    },
    [MutationTypes.USU_ULTEXERCICIO]({commit}) {
        //commit(MutationTypes.USU_ULTEXERCICIO,[]);

        axios.get(`exercicio/ultimo/`+state.Entidade)
        .then(function(response) {
            commit(MutationTypes.USU_ULTEXERCICIO,response.data);
        })
        .catch(function(response) {
            console.log(response)
        })
    },
    [MutationTypes.USU_IP]({commit}) {
        fetch('https://api.ipify.org?format=json')
            .then(x => x.json())
            .then(({ ip }) => {
                commit(MutationTypes.USU_IP,ip);
                axios.defaults.params = {'mct_ip': ip}
            })
            .catch(response => {
                console.log(response)
            });
    },
    [MutationTypes.LAYOUT]({commit}, response) {
      commit(MutationTypes.LAYOUT, response);
    },

};

const getters = {
    infoLogado: state => state.infoLogado,
    infoModulo: state => state.infoModulo,
    usuLogado: state => state.usuLogado,
    Entidade: state => state.Entidade,
    Escola: state => state.Escola,
    EntidadeOrc: state => state.EntidadeOrc,
    Exercicio: state => state.Exercicio,
    Competencia: state => state.Competencia,
    infoSubModulo: state => state.infoSubModulo,
    infoMenu: state => state.infoMenu,
    infoMenuSideBar: state => state.infoMenuSideBar,
    infoEntidade: state => state.infoEntidade,
    infoEscola: state => state.infoEscola,
    baseURL: state => state.baseURL,
    IP: state => state.IP,
    layout: state => state.layout,
};


export default{
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
